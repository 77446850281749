<template>
  <div class="app-container">
    <eHeader  :query="query"/>
    <!--表格渲染-->
    <el-table v-loading="loading" :data="data" size="small" stripe style="width: 100%;">
      <el-table-column prop="platformId" label="ID"/>
      <el-table-column prop="platformName" label="平台名称"/>
      <el-table-column prop="platformCode" label="平台编码"/>
      <el-table-column prop="account" label="短信账号"/>
      <el-table-column prop="password" label="短信密码"/>
      <el-table-column prop="url" label="url"/>
      <el-table-column prop="singlePhoneNum" label="单批发送最大电话数"/>
      <el-table-column prop="batchSmsNum" label="批量发送批次最大数"/>
      <el-table-column prop="batchPhoneNum" label="批量发送每批次最大电话数"/>
      <el-table-column label="操作"  align="center">
        <template slot-scope="scope">
          <edit v-if="checkPermission(['ADMIN','MERCHANT_ALL','MERCHANT_EDIT'])" :data="scope.row" :sup_this="sup_this"/>
          <el-popover
            v-if="checkPermission(['ADMIN','USER_ALL','USER_DELETE'])"
            :ref="scope.row.platformId"
            placement="top"
            width="180">
            <p>确定删除本条数据吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="$refs[scope.row.platformId].doClose()">取消</el-button>
              <el-button :loading="delLoading" type="primary" size="mini" @click="softDelete(scope.row)">确定</el-button>
            </div>
            <el-button slot="reference" type="danger" size="mini">删除</el-button>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <el-pagination
      :total="total"
      style="margin-top: 8px;"
      layout="total, prev, pager, next, sizes"
      @size-change="sizeChange"
      @current-change="pageChange"/>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission'
import initData from '@/mixins/initSmsData'
import { parseTime } from '@/utils/index'
import eHeader from '@/components/sms/interface/header'
import edit from '@/components/sms/interface/edit'
import { updatePlatform } from '@/api/sms'
export default {
  components: { eHeader, edit },
  mixins: [initData],
  data() {
    return {
      delLoading: false, sup_this: this,formData:{}
    }
  },
  created() {
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    parseTime,
    checkPermission,
    beforeInit() {
      this.url = '/jrqsms/api/platform/listPlatform'
      const query = this.query
      const platformCode = query.platformCode
      const platformName = query.platformName
      this.formData = { currentPage: this.page, pageSize: this.size }
      if (platformCode !== '' && platformCode !== null) { this.formData['platformCode'] = platformCode }
      if (platformName !== '' && platformName !== null) { this.formData['platformName'] = platformName } 
      this.params = JSON.stringify(this.formData)
      return true
    },
    softDelete(info) {
      this.delLoading = true
      info.status=1
      this.formData = info
      this.params = JSON.stringify(this.formData)
      updatePlatform(this.params).then(res => {
        this.delLoading = false
        this.$refs[info.platformId].doClose()
        this.init()
        this.$notify({
          title: '修改成功',
          type: 'success',
          duration: 2500
        })
      }).catch(err => {
        this.delLoading = false
        this.$refs[info.platformId].doClose()
        console.log(err.response.data.message)
      })
    }    
  }
}
</script>

<style scoped>

</style>
