<template>
  <el-dialog :visible.sync="dialog" :title="isAdd ? '新增短信平台' : '编辑短信平台'" append-to-body width="700px">
    <el-form ref="form" :model="form" :rules="rules" size="small" label-width="120px">
      <el-form-item label="平台名称" prop="platformName">
        <el-input v-model="form.platformName" style="width: 500px;"/>
      </el-form-item>
      <el-form-item label="平台编码" prop="platformCode">
        <el-input v-model="form.platformCode" style="width: 500px;"/>
      </el-form-item>
      <el-form-item label="短信账号" prop="account">
        <el-input v-model="form.account" style="width: 500px;"/>
      </el-form-item>
      </el-form-item>
      <el-form-item label="短信密码" prop="password">
        <el-input v-model="form.password" style="width: 500px;"/>
      </el-form-item>
      </el-form-item>
      <el-form-item label="url" prop="url">
        <el-input v-model="form.url" style="width: 500px;"/>
      </el-form-item>
      </el-form-item>
      <el-form-item label="单批发送最大电话数" prop="singlePhoneNum">
        <el-input v-model.number="form.singlePhoneNum" style="width: 500px;"/>
      </el-form-item>
      </el-form-item>
      <el-form-item label="批量发送批次最大数" prop="batchSmsNum">
        <el-input v-model.number="form.batchSmsNum" style="width: 500px;"/>
      </el-form-item>
      </el-form-item>
      <el-form-item label="批量发送每批次最大电话数" prop="batchPhoneNum">
        <el-input v-model.number="form.batchPhoneNum" style="width: 500px;"/>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { insertPlatform, updatePlatform } from '@/api/sms'
import { parseTime } from '@/utils/index'
export default {
  name: 'Form',
  props: {
    isAdd: {
      type: Boolean,
      required: true
    },
    sup_this: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      dialog: false, loading: false, form: {platformId: '',platformName: '', platformCode: '', account: '', password: '', url: '', singlePhoneNum: '', batchSmsNum: '', batchPhoneNum: ''},
      rules: {
        platformName: [
          { required: true, message: '请输入平台名称', trigger: 'blur' }
        ],
        platformCode: [
          { required: true, message: '请输入平台编码', trigger: 'blur' }
        ],
        account: [
          { required: true, message: '请输入短信账号', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入短信密码', trigger: 'blur' }
        ],
        url: [
          { required: true, message: '请输入url', trigger: 'blur' }
        ],
        singlePhoneNum: [
          { required: true, message: '请输入单批发送最大电话数', trigger: 'blur' },
          { type: 'number', message: '单批发送最大电话数必须为数字值'}
        ],
        batchSmsNum: [
          { required: true, message: '请输入批量发送批次最大数', trigger: 'blur' },
          { type: 'number', message: '批量发送批次最大数必须为数字值'}
        ],
        batchPhoneNum: [
          { required: true, message: '请输入批量发送每批次最大电话数', trigger: 'blur' },
          { type: 'number', message: '批量发送每批次最大电话数必须为数字值'}
        ]
        }
    }
  },
  methods: {
    cancel() {
      this.resetForm()
    },
    doSubmit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true
          if (this.isAdd) {
            this.doAdd()
          } else {
            this.doEdit()
          } 
        } else {
          return false
        }
      })
    },
    doAdd() {
      this.formData = this.form
      this.params = JSON.stringify(this.formData)
      insertPlatform(this.params).then(res => {
        this.resetForm()
        this.$notify({
          title: '添加成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.$parent.$parent.init()
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    doEdit() {
      this.formData = this.form 
      this.params = JSON.stringify(this.formData)
      updatePlatform(this.params).then(res => {
        this.resetForm()
        this.$notify({
          title: '修改成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.sup_this.init()
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    resetForm() {
      this.dialog = false
      this.$refs['form'].resetFields()
      this.form = { platformId: '',platformName: '', platformCode: '', account: '', password: '', url: '', singlePhoneNum: '', batchSmsNum: '', batchPhoneNum: ''}
    }
  }
}
</script>

<style scoped>

</style>
