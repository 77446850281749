<template>
  <div>
    <el-button size="mini" type="success" @click="to">编辑</el-button>
    <eForm ref="form"  :sup_this="sup_this" :is-add="false"/>
  </div>
</template>
<script>
import eForm from './form'
export default {
  components: { eForm },
  props: {
    data: {
      type: Object,
      required: true
    },
    // index.vue 的this 可用于刷新数据
    sup_this: {
      type: Object,
      required: true
    }
  },
  methods: {
    to() {
      const _this = this.$refs.form
      _this.form ={ platformId:this.data.platformId,platformName: this.data.platformName, platformCode: this.data.platformCode, account: this.data.account, password: this.data.password, url: this.data.url, singlePhoneNum: this.data.singlePhoneNum, batchSmsNum: this.data.batchSmsNum, batchPhoneNum: this.data.batchPhoneNum}, 
      _this.dialog = true
    }
  }
}
</script>

<style scoped>
  div{display: inline-block;margin-right: 3px;}
</style>
